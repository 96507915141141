import { createContext, ReactNode, useContext } from 'react';
import { TenantOrderContext } from '~/tenants/common/TenantOrderContext';
import { TenantPackageConfig } from '~/tenants/common/registry';
import { TenantPerformableConfig } from '~/tenants/common/TenantJob';
import PerformableGroupForm, { PerformableGroupProvider } from '~/components/performable/PerformableGroupForm';

interface Context<O extends TenantOrderContext = TenantOrderContext> {
  orderContext: TenantOrderContext;
  package: TenantPackageConfig;
}

const PackageFormContext = createContext<Context | null>(null);

export function usePackageFormContext<O extends TenantOrderContext = TenantOrderContext>() {
  const context = useContext<Context<O>>(PackageFormContext as any);

  if (!context) {
    throw new Error('Must be used within a PackageForm');
  }

  return {
    package: context.package,
    orderContext: context.orderContext,
  };
}

export function PackageFormNested(props: { children?: ReactNode; performable: TenantPerformableConfig }) {
  return <PerformableGroupForm performable={props.performable}>{props.children}</PerformableGroupForm>;
}

export default function PackageForm(props: {
  package: TenantPackageConfig;
  children: ReactNode;
  orderContext: TenantOrderContext;
}) {
  const configs = props.package.performables.map((performable) =>
    'config' in performable ? performable.config : performable,
  );

  return (
    <PackageFormContext.Provider value={{ package: props.package, orderContext: props.orderContext }}>
      <PerformableGroupProvider order={props.orderContext} required={configs}>
        {props.children}
      </PerformableGroupProvider>
    </PackageFormContext.Provider>
  );
}
